<template>
  <section>
    <v-dialog
      v-model="showModal"
      max-width="700px"
    >
      <v-card
        shaped
        color="#ECEFF1"
      >
        <br>
        <h2 class="modal-title-style">
          <v-icon class="modal-icon-style">
            mdi-checkbox-marked-outline
          </v-icon> Confirmation
        </h2>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="8"
                >
                  <p
                    class="my-p-style"
                  >
                    <font>
                      Payment method type <v-icon color="#2E7D32">
                        mdi-checkbox-marked-circle
                      </v-icon>
                    </font>
                  </p>
                  <v-text-field
                    v-model="paymentMethod"
                    outlined
                    label="Payment Method*"
                    disabled
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="red darken-3"
            @click="showModal = false"
          >
            Cancel
          </v-btn>
          <v-spacer />
          <v-btn
            class="mt-2"
            color="#FF3700"
            :disabled="!isFormValid"
            @click="confirm()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
      <centre-spinner
        :loading="loading"
      />
    </v-dialog>
  </section>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
  name: 'ProductPurchaseDeatils',
  components: {
    'centre-spinner': spinner,
  },
  data () {
    return {
      showModal: true,
      loading: false,
      paymentMethod: 'card',
    };
  },
  computed: {
    isFormValid () {
      if (this.paymentMethod !== '') {
        return true;
      }
      return false;
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  methods: {
    confirm () {
      this.$emit('purchase-confirm', this.paymentMethod);
    },
  },
};
</script>
<style scoped>
.my-p-style {
  font-size: 18px;
  font-weight: bold;
  color: #546E7A;
  font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}
.modal-title-style {
  color: #37474F;
  margin-left: 30px;
}
.modal-icon-style {
  color: #37474F;
  font-size: 45px;
}
</style>
