<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <div>
      <stripe-checkout
        ref="checkoutRef"
        :pk="publishableKey"
        :session-id="sessionId"
      />
      <overlay-alert
        :show-progress="showProgress"
        :process-alert="processAlert"
      />
      <purchase-confirm-details
        v-if="showConfirmForm"
        @purchase-confirm="purchaseConfirm"
        @closed="showConfirmForm = false"
      />
    </div>
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-cart-outline
      </v-icon> Purchase Cart
    </h2>
    <br>
    <v-alert
      v-if="showCheckoutAlert"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="checkout-alert"
    >
      Please do not refresh or close your browser while we process your subscription.
    </v-alert>
    <v-card
      class="my-base-vcard-style"
    >
      <br>
      <div>
        <v-card
          class="my-vcard-style"
        >
          <div
            v-if="!showCheckoutAlert"
            class="cart-empty"
          >
            <br>
            <v-icon
              color="#C62828"
              size="50"
            >
              mdi-alert-outline
            </v-icon>
            <h4>
              Your Cart is Empty!
            </h4>
          </div>
          <v-container>
            <div v-if="productsCart.length > 0">
              <v-list
                color="#ECEFF1"
              >
                <v-list-item-group>
                  <template v-for="(item, index) in productsCart">
                    <v-list-item :key="'item'+ index">
                      <template>
                        <div class="board-div">
                          <v-icon
                            color="#37474F"
                            size="80"
                          >
                            mdi-monitor-screenshot
                          </v-icon>
                          <br>
                          <font class="board-type">
                            {{ item.digiBoardDetails.digi_board.digi_board_specification.display_type }}
                          </font>
                          <br>
                          <font class="board-dimension">
                            {{ item.digiBoardDetails.digi_board.height }} * {{ item.digiBoardDetails.digi_board.width }}
                          </font>
                        </div>
                        <v-list-item-content
                          class="ml-1"
                        >
                          <v-list-item-title>
                            <span class="product-header">
                              {{ item.digiBoardDetails.digi_board.external_id }} - {{ item.digiBoardDetails.digi_board.location }}, {{ item.digiBoardDetails.time_slot.name }} Slot
                            </span>
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            <span class="product-header">
                              {{ item.productPrice.name }}
                            </span>
                          </v-list-item-subtitle>
                          <div class="action-div">
                            <font
                              :color="getColor(item.digiBoardDetails.purchase_status)"
                              class="font-my-style"
                            >
                              <span>{{ item.digiBoardDetails.purchase_status | statusUpdate }}</span>
                            </font> |
                            <v-btn
                              color="#FF3700"
                              text
                              small
                              @click="removeProduct(index)"
                            >
                              <v-icon left>
                                mdi-close
                              </v-icon>
                              Remove
                            </v-btn>
                          </div>
                        </v-list-item-content>
                        <div
                          class="amount-display"
                        >
                          <span class="currency-style">{{ item.productPrice.currency.toUpperCase() }}</span> <span class="display-amount">{{ item.productPrice.amount | amountConverter }}</span>
                        </div>
                      </template>
                    </v-list-item>
                    <v-divider
                      v-if="index < productsCart.length - 1"
                      :key="index"
                    />
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-container>
        </v-card>
        <div class="purchase-button">
          <v-btn
            color="#37474F"
            @click="$router.push({name: 'Dashboard'})"
          >
            <v-icon left>
              mdi-home
            </v-icon>
            Home
          </v-btn>
          <v-btn
            :disabled="!showCheckoutAlert"
            color="#FF3700"
            @click="checkUserType()"
          >
            <v-icon left>
              mdi-cart-check
            </v-icon>
            {{ buttonName }}
          </v-btn>
        </div>
      </div>
      <br>
      <centre-spinner
        :loading="loading"
      />
    </v-card>
  </v-container>
</template>

<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import OverlayAlert from 'src/views/dashboard/component/OverlayAlert';
  import global from 'src/mixins/global';
  import { StripeCheckout } from '@vue-stripe/vue-stripe';
  import { stripePublishKey } from 'src/app-globals';
  import Constants from 'src/constants';
  import PurchaseConfirmDeatils from './PurchaseConfirmDetails.vue';

 export default {
    name: 'ProductPurchaseCart',
    components: {
      'centre-spinner': spinner,
      'overlay-alert': OverlayAlert,
      'stripe-checkout': StripeCheckout,
      'purchase-confirm-details': PurchaseConfirmDeatils,
    },
    filters: {
      statusUpdate (status) {
        if (status === 'sold') {
          return 'Sold*';
        }
        return 'Available*';
      },
       amountConverter (amount) {
        return amount / 100;
      },
    },
    mixins: [global],
    data () {
      return {
        loading: false,
        showProgress: false,
        processAlert: '',
        publishableKey: stripePublishKey,
        showConfirmForm: false,
        priceType: '',
        sessionId: '',
      };
    },
    computed: {
      productsCart () {
        return this.$store.getters['payments/getProductsCart'];
      },
       organizationDetails () {
        return this.$store.getters['userprofile/getOrganizationDetails'];
      },
      organizationPurchaseHistory () {
        return this.$store.getters['userprofile/getOrganizationPurchaseHistory'];
      },
      isFirstTimeUser () {
        if (this.organizationPurchaseHistory.length === 0) {
          return true;
        }
        return false;
      },
      showCheckoutAlert () {
        if (this.productsCart.length > 0) {
          return true;
        }
        return false;
      },
      buttonName () {
        if (this.isFirstTimeUser === true) {
          return 'Start Trial';
        }
        return 'Purchase';
      },
      userProfile () {
        return this.$store.getters['userprofile/getUserProfile'];
      },
      checkoutSession () {
        return this.$store.getters['payments/getCheckoutSession'];
      },
    },
    async mounted () {
      await this.fetchOrganizationDetails();
      await this.fetchOrganizationPurchaseHistory();
    },
    methods: {
      async fetchOrganizationDetails () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationDetails');
        this.loading = false;
      },
      async fetchOrganizationPurchaseHistory () {
        this.loading = true;
        await this.$store.dispatch('userprofile/fetchOrganizationPurchaseHistory', {
          organizationId: this.organizationDetails.external_id,
        });
        this.loading = false;
      },
      isPlanActive (priceStatus) {
        if (priceStatus === true) {
          return true;
        }
        return false;
      },
      removeProduct (index) {
       this.$store.dispatch('payments/removeCartedProducts', index);
      },
      checkUserType () {
        if (this.isFirstTimeUser === true) {
          this.startFreeTrial();
        } else {
          this.purchaseTimeSlots();
        }
      },
      getColor (status) {
        if (status === 'free') {
          return '#2E7D32';
        };
        return '#C62828';
      },
      async startFreeTrial () {
        if (this.permissionCheck('purchase-trial-time-slot') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showProgress = true;
        this.processAlert = 'Verifying...';
        await this.$store.dispatch('payments/startFreeTrial', {
         digiBoardTimeSlotId: this.productsCart[0].digiBoardDetails.id,
         priceId: {
          price_id: this.productsCart[0].productPrice.id,
         },
        }).then(response => {
          this.processAlert = 'Activating...';
          setTimeout(() => {
            this.$store.dispatch('alert/onAlert', {
              message: 'Free trial subscription activated successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.showProgress = false;
            this.$store.dispatch('payments/clearOffProductsCart');
            this.$router.push({ name: 'Digi-Boards' });
          }, 2000);
        }).catch(() => {
          this.showProgress = false;
        });
      },
      purchaseTimeSlots () {
        this.showConfirmForm = true;
      },
      purchaseConfirm (paymentMethod) {
        this.showConfirmForm = false;
        if (this.permissionCheck('create-payment-session') === false) {
          this.$store.dispatch('alert/onAlert', {
            message: 'You are not authorized to perform this action. Please contact your administrator.',
            type: Constants.ALERT_TYPE_INFO,
          });
          return;
        }
        this.showProgress = true;
        this.processAlert = 'Verifying...';
        this.priceType = this.productsCart[0].productPrice.type;
        this.createCheckoutCustomer(paymentMethod);
      },
      async createCheckoutCustomer (paymentMethod) {
        await this.$store.dispatch('payments/createCheckoutCustomer', {
          description: `${this.userProfile.first_name} - ${this.userProfile.external_id}`,
          payment_method_type: paymentMethod,
        }).then(response => {
          this.createCheckoutSession(paymentMethod);
        }).catch(() => {
          this.showProgress = false;
        });
      },
      async createCheckoutSession (paymentMethod) {
        this.processAlert = 'Confirming...';
        const digiBoardTimeSlots = [];
        for (const item of this.productsCart) {
          digiBoardTimeSlots.push({
            digi_board_time_slot_id: item.digiBoardDetails.id,
            price_id: item.productPrice.id,
          });
        }
        await this.$store.dispatch('payments/createCheckoutSession', {
          digi_board_time_slots: digiBoardTimeSlots,
          payment_method_types: paymentMethod.split(','),
          mode: this.priceType === 'recurring' ? 'subscription' : 'payment',
        }).then(response => {
          this.sessionId = this.checkoutSession.stripe_id;
          this.redirectToCheckout();
        }).catch(() => {
          this.showProgress = false;
        });
      },
      redirectToCheckout () {
        this.processAlert = 'Redirecting...';
        this.$refs.checkoutRef.redirectToCheckout();
      },
    },
  };
</script>
<style scoped>
.my-vcard-style {
  margin-right: 30px;
  margin-left: 30px;
  background-color: #ECEFF1;
  border-radius: 15px;
  border: 1px solid #37474F;
}
.my-base-vcard-style {
  background-color: #CFD8DC !important;
}
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.product-header {
  color: #37474F;
  font-size: 20px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  text-transform: capitalize;
}
.currency-style {
  color: #37474F;
  font-size: 22px;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.display-amount {
  color: #37474F;
  font-size: 21px;
  font-family: 'Times New Roman', Times, serif;
}
.checkout-alert {
  font-family: 'Times New Roman', Times, serif;
  color: #C62828;
  font-size: 17px;
}
.cart-empty {
  font-family: 'Times New Roman', Times, serif;
  color: #C62828;
  font-size: 25px;
  text-align: center;
}
.purchase-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.amount-display {
  text-align: right;
  margin-right: 10px;
}
.board-dimension {
  color: #37474F;
  font-weight: 500;
}
.board-type {
  text-transform: uppercase;
  color: #37474F;
  font-weight: 500;
}
.board-div {
  text-align: center;
  padding-right: 50px;
}
.action-div {
  padding-top: 10px;
}
.font-my-style {
  font-weight: 500;
}
</style>
